* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
  
.body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: transparent;
}
  
.wrapper {
  max-width: 450px;
  border: 2px solid teal;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  color: whitesmoke;
  padding: 30px 40px;
}
  
.wrapper form h1 {
  color: teal;
  text-align: center;
  font-size: 26px;
  margin-bottom: 20px;
}
  
.wrapper form div {
  text-align: center;
  margin-top: 10px;
}

.ahref {
  text-decoration: none;
  color: rgb(140, 199, 235);
}

.btn {
  display: flex;
  justify-content: center;
  height: 40px;
}

.pass {
  display: flex;
}

.pass button {
  margin: 5px 0;
  padding-right: 5px;
  width: 50px;
  border: 2px solid teal;
  border-left: none;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
  outline: none;
}

.pass button img {
  width: 90%;
  height: 70%;
}

.input_image {
  width: 100%;
  height: 50px;
  padding: 5px 10px;
  margin: 5px 0;
  border: 2px solid teal;
  border-right: none;
  border-radius: 10px 0 0 10px;
  color: teal;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  background-color: whitesmoke;
  outline: none;
}

.input_image::placeholder {
  font-size: 16px;
  color: teal;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


@media screen and (max-width: 800px) and (min-width: 360px) {
  .body {
    margin: 0 5px;
  }
}
@media screen and (max-width: 360px) {
  .body {
    margin: 15px 2px 0 2px;
  }
}