.content {
    display: flex;
    flex-direction: row;
    width: 800px;
    color: teal;
    flex-wrap: wrap;
    z-index: 2;
}

.content li {
    display: flex;
    align-items: center;
    width: 100%;
}

.content li p {
    margin-left: 10px;
    width: 100px;
    color: teal;
}

.priority_link {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 5px;
}

.priority_link p {
    margin-left: 10px;
    width: 100px;
    color: teal;
}

.txt_area {
    display: flex;
    flex-direction: column;
    width: 800px;
    height: 150px;
    padding: 10px;
    margin-top: 5px;
    color: teal;
    border: 2px solid teal;
    border-radius: 10px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    background: whitesmoke;
}

.txt_area::placeholder {
    color: darkgray;
}

.phone {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.btn {
    margin: 20px auto;
}

.my_modal {
    width: 100%;
    padding-bottom: 0;
    margin: 0 0 5px 10px;
}

.pass_form {
    margin-left: 90px;
    width: 100%;
}

.invisible {
    display: none;
}

.verify_modal {
    border: 2px solid teal;
    border-radius: 7px;
    margin: 0 5px 10px 10px;
    padding: 10px;
    color: whitesmoke;
    background: teal;
    width: 790px;
}

.pass {
    display: flex;
    width: 100%;
}

.pass button {
    margin: 5px 0;
    padding-right: 5px;
    width: 50px;
    border: 2px solid teal;
    border-left: none;
    border-radius: 0 10px 10px 0;
    cursor: pointer;
    outline: none;
}

.pass button img {
    width: 90%;
    height: 70%;
}

.input_image {
    width: 100%;
    height: 50px;
    padding: 5px 10px;
    margin: 5px 0;
    border: 2px solid teal;
    border-right: none;
    border-radius: 10px 0 0 10px;
    color: teal;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    background-color: whitesmoke;
    outline: none;
}

.input_image::placeholder {
    font-size: 16px;
    color: teal;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


@media screen and (max-width: 820px) and (min-width: 600px) {
    .content {
        flex-wrap: wrap;
        width: 550px;
    }

    .verify_modal {
        width: 540px;
    }

    .content li p {
        margin-left: 10px;
        width: 100px;
    }

    .txt_area {
        width: 100%;
    }

    .phone {
        margin-right: 0;
    }

    .my_modal {
        width: 540px;
        margin-top: 5px;
    }
}

@media screen and (max-width: 600px) and (min-width: 360px) {
    .content {
        flex-wrap: wrap;
        width: 95%;
        margin-top: 20px;
    }

    .content li {
        flex-direction: column;
        margin-left: 10px;
    }

    .content li p {
        margin-left: 10px;
        width: 100px;
        align-self: flex-start;
    }

    .priority_link {
        flex-direction: column;
        margin-left: 0;
    }

    .priority_link p {
        margin-left: 10px;
        width: 100px;
        align-self: flex-start;
    }

    .verify_modal {
        width: 340px;
    }

    .txt_area {
        width: 100%;
    }

    .phone {
        flex-wrap: wrap;
        margin-right: 0;
    }

    .my_modal {
        width: 100%;
    }

    .pass_form {
        margin-left: 0;
        margin-right: 10px;
    }
}

@media screen and (max-width: 360px) {
    .content {
        flex-wrap: wrap;
        width: 95%;
        margin-top: 20px;
    }

    .content li {
        flex-direction: column;
        margin-left: 10px;
    }

    .content li p {
        margin-left: 10px;
        width: 100px;
        align-self: flex-start;
    }

    .priority_link {
        flex-direction: column;
        margin-left: 0;
    }

    .priority_link p {
        margin-left: 10px;
        width: 100px;
        align-self: flex-start;
    }

    .txt_area {
        width: 100%;
    }

    .phone {
        flex-wrap: wrap;
        margin-right: 0;
    }

    .my_modal {
        width: 100%;
    }

    .verify_modal {
        width: 290px;
    }

    .pass_form {
        margin-left: 0;
        margin-right: 10px;
    }
}