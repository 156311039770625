.invisible {
    display: none;
}

.modal {
    color: teal;
}

.hidden {
    opacity: 0;
    height: 0;
    width: 0;
    line-height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

.button {
    width: 90px;
    height: 35px;
    border: 2px solid teal;
    border-radius: 8px;
    color: teal;
    margin-top: 5px;
}

.uploader {
    display: flex;
    gap: 5px;
}

.file_status {
    color: teal;
    /*margin: 5px;*/
    display: flex;
    gap: 5px;
}

.filename {
    font-weight: 600;
    font-size: 16px;
}

.bad_status {
    color: red;
}

.good_status {
    color: teal;
}

.load_info {
    margin-top: 10px;
}
