.main {
    margin-bottom: 20px;
}

.header {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    padding: 20px 40px;
    background: whitesmoke;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 20px;
    /*margin-top: 10px;*/
    transition: transform .4s linear;
}

.header.hide {
    transform: translateY(-51px);
}

.logo {
    font-size: 32px;
    color: teal;
    text-decoration: none;
    font-weight: 700;
    z-index: 5;
    position: relative;
}

.navbar {
    display: block;
    background: whitesmoke;
    margin-top: 10px;
    z-index: 100;
}

.navbar a {
    position: relative;
    font-size: 18px;
    color: teal;
    font-weight: 500;
    text-decoration: none;
    margin-left: 15px;
    background: transparent;
    z-index: 20;
}

.navbar a::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 0;
    height: 2px;
    transition: .5s;
    background: teal;
}

.navbar a:hover::before {
    width: 30%;
}

.burger {
    display: none;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    width: 30px;
    height: 20px;
    cursor: pointer;
    color: teal;
}

.burger span {
    height: 2px;
    width: 100%;
    background-color: teal;
    transform: scale(1);
    position: absolute;
}

.burger::before, .burger::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    transition: all .5s ease 0s;
    background-color: teal;
}

.burger::after {
    bottom: 0;
}

.burger::before {
    top: 0;
}

.burger.active span {
    transform: scale(0);
}

.burger.active::before {
    top: 50%;
    transform: rotate(-45deg) translate(0, -50%);
}

.burger.active::after {
    bottom: 50%;
    transform: rotate(45deg) translate(0, 50%);
}

@media screen and (max-width: 760px) {
    .main {
        margin-bottom: 60px;
    }
    .header {
        padding: 10px 20px;
        margin-top: 3px;
        position: fixed;
    }

    .burger {
        display: flex;
        z-index: 100;
    }

    .logo.active_logo {
        color: transparent;
        z-index: 5;
        position: relative;
    }

    .navbar {
        display: none;
    }
    .navbar.active {
        display: flex;
        flex-direction: column;
        justify-content: left;
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        overflow-y: auto;
        padding: 20px 0;
        row-gap: 10px;
        background: transparent;
        animation: burgerAnimation .5s;
        /*margin-bottom: 50px;*/
        backdrop-filter: blur(15px);

    }
}

@keyframes burgerAnimation {
    from {opacity: 0}
    to {opacity: 1}
}