.client {
    margin: 20px;
    max-width: 700px;
}

.client ul {
    text-decoration: none;
    color: teal;
    font-size: 18px;
    list-style-type: none;
    margin-top: 10px;
    border: 2px solid teal;
    border-radius: 20px;
    padding: 15px;
    max-width: 700px;
}

.client ul li {
    display: flex;
    gap: 15px;
    align-items: center;
}

.client ul li p {
    width: 80px;
    align-items: flex-start;
    flex-wrap: wrap;
}

.client ul hr {
    margin: 5px;
}

.client h3 {
    color: teal;
    margin-top: 10px;
}

.client ul li textarea {
    display: block;
    width: 100%;
    height: 170px;
    font-size: 17px;
    font-weight: 700;
    padding: 8px;
    border: none;
    resize: none;
    color: teal;
}

.loader {
    display: flex;
    justify-content: center;
}

.new__note {
    background: transparent;
    backdrop-filter: blur(6px);
    justify-content: center;
    align-content: center;
    max-width: 700px;
}

.new__note div {
    display: flex;
    justify-content: center;
    background: transparent;
    gap: 20px;
    margin-top: 10px;
}

.none {
    display: none;
}

.add_note_button {
    margin-top: 5px;
    margin-bottom: 5px;
}

.new__note textarea {
    border: 2px solid teal;
    border-radius: 20px;
    width: 100%;
    height: 150px;
    padding: 10px;
    font-size: 16px;
    color: teal;
    margin-top: 10px;
}

.btns {
    display: flex;
    gap: 7px;
    flex-wrap: wrap;
}

.notes {
    color: teal;
}

.notes p {
    margin: 2px auto;
}

.file {
    margin-top: 10px;
    margin-bottom: 10px;
}

.file_action {
    display: flex;
    gap: 5px;
    color: teal;
    margin-top: 5px;
}

.extract {
    height: 40px;
    border: 2px solid teal;
    border-radius: 8px;
    color: teal;
    margin-top: 5px;
    font-size: 18px;
    padding: 0 7px;
    cursor: pointer;
}

@media screen and (max-width: 500px) and (min-width: 321px) {
    .btns {
        flex-direction: column;
        width: 100%;
        margin: auto;
    }
    .client h3 {
        text-align: center;
    }
    .new__note textarea {
        height: 250px;
    }
}

@media screen and (max-width: 320px) {
    .btns {
        flex-direction: column;
        width: 100%;
        margin: auto;
    }
    .client h3 {
        text-align: center;
    }
    .new__note div {
        gap: 10px;
    }
}