.content {
    display: flex;
    flex-direction: column;
    margin: 15px;
}

.link {
    color: teal;
    text-decoration: none;
    font-size: 18px;
}