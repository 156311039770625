.activation_response {
    color: teal;
    margin: 10px 0 0 10px;
    /*margin: 100px;*/
}

.loader {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 780px) {
    .activation_response {
        margin: 50px 0 0 10px;
    }
}