.context {
    color: teal;
    margin-left: 5px;
    max-width: 700px;
}

.loader {
    display: flex;
    justify-content: center;
}

.btns {
    display: flex;
    gap: 5px;
    margin: 5px 0 5px 5px;
}

.btn_active {
    border: 2px solid teal;
    border-radius: 10px;
    color: whitesmoke;
    background: teal;
    width: 100px;
    height: 35px;
    font-size: 16px;
}

.btn_inactive {
    border: 2px solid teal;
    border-radius: 10px;
    color: teal;
    background: whitesmoke;
    width: 100px;
    height: 35px;
    font-size: 16px;
}

.invisible {
    display: none;
}

.entries {
    text-decoration: none;
    color: teal;
}

.range {
    margin: 3px 0 5px 0;
    display: flex;
    gap: 5px;
    align-items: center;
}

.input_date {
    min-height: 35px;
    border: 2px solid teal;
    border-radius: 5px;
    color: teal;
}

.select {
    max-width: 272px;
}

.radio {
    margin-left: 5px;
}

.radio div {
    display: flex;
    gap: 3px;
}

.plug {

}

.plug img {
    max-width: 700px;
}

.answer {
    border: 2px solid teal;
    border-radius: 8px;
    padding: 5px;
}

.answer_pair {
    margin-bottom: 5px;
}

.graph {
    max-width: 700px;
}

.hidden {
    visibility: hidden;
    height: 0;
    width: 0;
}

.accord_container {
    margin: 5px;
}
.accord_body {
    width: calc(100% - 40px);
    margin: 0 auto;
    height: 0;
    color: teal;
    background-color: whitesmoke;
    line-height: 18px;
    padding: 0 10px;
    box-sizing: border-box;
    transition: color 0.5s, padding 0.5s;
    overflow: hidden;
    font-family: Verdana, sans-serif;
    font-size: 16px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2), 0 10px 16px rgba(0,0,0,0.2);
}
.accord_body div {
    padding: 3px;
}
.accord_container label {
    cursor: pointer;
    background-color: whitesmoke;
    display: block;
    max-width: 230px;
    color: teal;
    font-weight: 300;
    box-sizing: border-box;
    z-index: 100;
    font-family: Verdana, sans-serif;
    margin: 0 0 5px;
    transition: color .35s;
}
.accord_container label:hover {
    color: #3db4b4;
}
.accord_container input{
    display: none;
}
.accord_container label:before {
    content: '\276F';
    float: right;
}
.accord_container input:checked + label {
    background-color: whitesmoke;
    color: teal;
}
.accord_container input:checked + label:before {
    transition: transform .35s;
    transform: rotate(90deg);
}
.accord_container input:checked + label + .accord_body {
    height: auto;
    margin-left: 5px;
    color: teal;
    padding: 10px;
}

.hr {
    background: linear-gradient(45deg, teal, #47a8a8, whitesmoke);
    margin: 2px 0;
    height: 2px;
    border: none;
    width: 90%;
}

@media screen and (max-width: 600px) and (min-width: 360px) {
    .graph {
        max-width: 98%;
    }
}