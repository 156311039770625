.content {
    color: teal;
    margin: 10px;
}

.radio_change {
    gap: 10px;
    display: flex;
    margin: 5px 0 10px 5px;
    flex-wrap: wrap;
}

.radio_change div {
    display: flex;
    gap: 2px;
}

.users {
    text-decoration: none;
    color: teal;
    margin-left: 5px;
}

.user_list {
    max-width: 700px;
}