.content {
    margin: 20px 10px;
    color: teal;
    max-width: 600px;
}

.content textarea {
    border: 2px solid teal;
    border-radius: 10px;
    width: 100%;
    height: 60px;
    padding: 8px;
    margin-top: 5px;
    color: teal;
}

.content textarea::placeholder {
    color: teal;
    font-size: 16px;
}

.welcome {
    max-width: 700px;
    margin: 5px 0;
}

.form {
    margin-top: 10px;
}

.create_button_disabled {
    display: none;
}

.create_button {
    display: flex;
}

.btns {
    margin-top: 10px;
    display: flex;
    gap: 20px;
    justify-content: center;
}

.modal {
    margin-top: 10px;
}

.checkbox {
    margin: 5px 0;
}

.checkbox div {
    display: flex;
    gap: 8px;
    color: teal;
}

.cons_group {
    margin-top: 5px;
}

.cons_group_invisible {
    display: none;
}

.group_list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
}

.cons_group div hr {
    background: linear-gradient(45deg, teal, #47a8a8, whitesmoke);
    margin: 2px 0;
    height: 2px;
    border: none;
    width: 90%;
}

.plug {

}

.cons_buttons {
    display: flex;
    gap: 7px;
    margin-bottom: 10px;
    /*width: 100%;*/
}

.cons_button_inactive {
    /*width: 180px;*/
    height: 60px;
    border: 2px solid teal;
    border-radius: 8px;
    color: teal;
    font-size: 16px;
    background: whitesmoke;
    cursor: pointer;
    padding: 5px 3px;
}

.cons_button_active {
    /*width: 180px;*/
    height: 60px;
    border: 2px solid teal;
    border-radius: 8px;
    color: whitesmoke;
    font-size: 16px;
    background: teal;
    cursor: pointer;
    padding: 5px 3px;
}

.meetings {
    margin-top: 10px;
}


.new_meeting {
    border: 2px solid teal;
    border-radius: 10px;
    max-width: 700px;
    padding: 10px 5px;
}

.new_meeting_row {
    display: flex;
    gap: 3px;
    align-items: center;
    margin-bottom: 5px;
}

.new_meeting_row p {
    width: 25%;
}

.new_meeting_row input {
    border: 2px teal solid;
    border-radius: 8px;
    color: teal;
    width: 75%;
    height: 40px;
    padding: 0 3px;
}

.self_entry {
    display: flex;
    gap: 7px;
}

.self_entry input {
    width: 17px;
}

.new_meeting_buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 10px;
}

.new_meeting_buttons button {
    width: 100px;
    height: 40px;
    border: 2px solid teal;
    border-radius: 8px;
    color: teal;
    font-size: 16px;
    background: whitesmoke;
    cursor: pointer;
}

.date {
    width: 100%;
    height: 50px;
    color: teal;
    border: 2px solid teal;
    border-radius: 8px;
    margin: 5px 0;
    padding: 5px 10px;
}

@media screen and (max-width: 500px) {
    .content {
        width: 98%;
    }

    .new_meeting {
        margin-right: 10px;
    }

    .new_meeting_row {
        flex-direction: column;
    }

    .new_meeting_row p {
        width: 100%;
    }

    .new_meeting_row input {
        width: 100%;
    }

    .cons_buttons {
        flex-direction: column;
        margin-right: 10px;
    }

    .cons_button_active {
        width: 100%;
    }

    .cons_button_inactive {
        width: 100%;
    }

    .welcome {
        width: 100%;
    }

    .welcome h4 {
        margin: 10px 5px 10px 0;
    }
}