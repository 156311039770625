.stats {
    margin: 0 10px;
    color: teal;
    max-width: 700px;
}

.stat_type {
    display: flex;
    flex-direction: column;
    margin: 3px 0;
}

.stat_type div {
    display: flex;
    gap: 3px;
}

.plug {

}

.invisible {
    display: none;
}

