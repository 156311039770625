.modal {
    height: 50px;
    width: 100%;
    background-color: rgba(0,0,0,0.7);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
}

.modal.active {
    transform: scale(1);
    justify-content: center;
    align-items: center;
}

.modal_content {
    padding: 15px;
    border-radius: 10px;
    background-color: #049f9f;
    color: white;
    margin: 0 auto;
}