.content {
    color: teal;
    max-width: 700px;
    margin-right: 5px;
}

.content h3 {
    margin-left: 10px;
}

.block {
    margin-left: 10px;
    color: teal;
}

.block legend {
    color: teal;
    font-weight: 600;
}

.block hr {
    width: 50%;
    margin: 5px 0 5px 0;
}

.block input {
    margin-right: 5px;
}

.invisible {
    display: none;
}

.prevent {
    margin: 5px 0 10px 10px;
}

.btn {
    border: 2px solid whitesmoke;
    background-color: teal;
    border-radius: 7px;
    width: 40px;
    height: 40px;
    color: whitesmoke;
    font-weight: 600;
    font-size: 16px;
}

.btn2 {
    border: 2px solid teal;
    background-color: whitesmoke;
    border-radius: 7px;
    width: 40px;
    height: 40px;
    color: teal;
    font-weight: 600;
    font-size: 16px;
}

.questions {
    display: flex;
    gap: 1px;
    flex-wrap: wrap;
    margin-left: 5px;
}
 .radio {
     margin-top: 5px;
     margin-bottom: 5px;
 }

 .text {
     margin-top: 5px;
     margin-bottom: 5px;
 }

 .text textarea {
     border: 2px solid teal;
     border-radius: 7px;
     width: 400px;
     height: 50px;
     padding: 5px;
     color: teal;
 }

 .text h4 {
     margin-bottom: 3px;
 }

 .recomend {
     margin-top: 10px;
     color: teal;
 }

 .recomend p {
     margin-bottom: 5px;
 }

 .p_style {
     margin-bottom: 10px;
 }

 .types_quest {
     margin: 5px 0 10px 10px;
 }

 .active_type_button {
     border: 2px solid whitesmoke;
     background-color: teal;
     border-radius: 7px;
     width: 100px;
     height: 45px;
     color: whitesmoke;
     font-weight: 600;
     font-size: 16px;
     word-wrap: normal;
 }

.inactive_type_button {
    border: 2px solid teal;
    background-color: whitesmoke;
    border-radius: 7px;
    width: 100px;
    height: 45px;
    color: teal;
    font-weight: 600;
    font-size: 16px;
}

.agreement {
    margin: 5px 0 5px 10px;
}

.agreement_radio {
    display: flex;
    gap: 15px;
    margin-top: 5px;
}

.range {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 5px;
}

.range span {
    font-weight: 700;
    border: 2px solid teal;
    border-radius: 7px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*padding: 0 2px;*/
}

.href {
    text-decoration: none;
    color: #049f9f;
}

.picture {
    display: flex;
    justify-content: center;
}

.picture img {
    width: 96%;
    max-width: 1190px;
    max-height: 1682px;
}

.hidden {
    visibility: hidden;
}