.content {
    margin-left: 10px;
    max-width: 700px;
}

.meeting_card {
    border: 2px solid teal;
    border-radius: 8px;
    color: teal;
    padding: 5px;
}

.meeting_card hr {
    background: linear-gradient(45deg, teal, #47a8a8, whitesmoke);
    margin: 2px 0;
    height: 2px;
    border: none;
    width: 90%;
}

.meeting_card div {
    display: flex;
    align-items: center;
    gap: 7px;
    margin: 10px 0;
}

.meeting_card div p {
    width: 20%;
}

.meeting_card div h4 {
    width: 80%;
}

.meeting_buttons {
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.meeting_buttons button {
    width: 120px;
    height: 45px;
    border: 2px solid teal;
    border-radius: 8px;
    color: teal;
    font-size: 16px;
    background: whitesmoke;
    cursor: pointer;
    padding: 3px;
}

.clients {
    color: teal;
}

.modal {
    margin-top: 20px;
}

.invisible {
    display: none;
}

.plug {

}

.edit_meeting {
    margin-top: 10px;
    color: teal;
}

@media screen and (max-width: 500px) {
    .meeting_card {
        margin-right: 5px;
    }

    .meeting_card div {
        flex-direction: column;
        gap: 3px;
    }

    .meeting_card div p {
        width: 100%;
    }

    .meeting_card div h4 {
        width: 100%;
    }

    .modal {
        margin-right: 5px;
    }
}