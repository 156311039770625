.body {
    margin: 10px;
}

.buttons {
    display: flex;
    gap: 7px;
    min-height: 40px;
    width: 100%;
    margin-bottom: 10px;
}

.invisible {
    display: none;
}

.new_dir {
    margin-top: 10px;
}

.direction_content {
    max-width: 600px;
    color: teal;
}

.check_box {
    display: flex;
    gap: 8px;
}

.btn {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    gap: 7px;
    height: 40px;
}

.modal {
    margin: 10px 0;
}

.dirs_list a {
    text-decoration: none;
    color: teal;
    font-size: 18px;
}

.checkbox {
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
}

.limit {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 5px;
}

.limit input{
    width: 50px;
    height: 40px;
    border: 2px solid teal;
    border-radius: 7px;
    color: teal;
    padding-left: 10px;
}

@media screen  and (max-width: 760px){
    .buttons {
        flex-wrap: wrap;
    }
}