.loader {
    width: 50px;
    height: 50px;
    border-radius: 45%;
    border: 13px dotted teal;
    animation: rotate 2s infinite linear;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg) scale(.7);
    }
    20% {
        transform: rotate(72deg) scale(.85);
    }
    40% {
        transform: rotate(144deg) scale(1);
    }
    60% {
        transform: rotate(216deg) scale(1);
    }
    80% {
        transform: rotate(288deg) scale(.85);
    }
    100% {
        transform: rotate(360deg) scale(.7);
    }
}