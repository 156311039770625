.MySelect {
    height: 50px;
    width: 100%;
    padding: 5px 10px;
    margin: 5px 0;
    border: 2px solid teal;
    border-radius: 10px;
    color: teal;
    font-size: 16px;
    background-color: whitesmoke;
}

.option {
    background-color: whitesmoke;
    cursor: pointer;
}