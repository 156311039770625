
.content {
    margin-left: 10px;
    color: teal;
}

.content h4 {
    margin-top: 10px;
    width: 75%;
}

.buttons {
    display: flex;
    gap: 5px;
    height: 40px;
}

.groups {
    margin-top: 10px;
    display: flex;
}

.groups h3 {
    margin-bottom: 5px;
}

.invisible {
    display: none;
}
