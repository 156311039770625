.loader {
    display: flex;
    justify-content: center;
}

.direction_card {
    color: teal;
    max-width: 700px;
    border: 2px solid teal;
    border-radius: 10px;
    padding: 5px;
    margin: 5px;
}

.direction_card div div {
    display: flex;
    margin: 5px 0;
}

.direction_card div p {
    min-width: 150px;
}

.direction_card hr {
    color: teal;
}

.buttons {
    margin: 5px;
    display: flex;
    gap: 5px;
}

.invisible {
    display: none;
}

.btn {
    display: flex;
    min-height: 40px;
}

.btn button {
    border: 2px solid teal;
    border-radius: 7px;
    height: 45px;
    width: 150px;
    padding: 2px 5px;
    color: teal;
    font-size: 16px;
    cursor: pointer;
}

.checkbox {
    display: flex;
    gap: 5px;
}

.change_dir {
    color: teal;
    max-width: 700px;
    margin: 5px;
}

.modal {
    max-width: 700px;
    margin: 5px;
}

.graph {
    color: teal;
    max-width: 700px;
}

.graph div {
    margin-left: 5px;
}

.graph div img {
    max-width: 700px;
}

.graph div h2 {
    margin-bottom: 10px;
}

.range {
    margin: 3px 0 5px 0;
    display: flex;
    gap: 5px;
    align-items: center;
}

.groups_list {
    text-decoration: none;
    color: teal;
}

.input_date {
    min-height: 35px;
    border: 2px solid teal;
    border-radius: 5px;
    color: teal;
}

.limit {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 5px;
}

.limit input{
    max-width: 40px;
    height: 30px;
    border: 2px solid teal;
    border-radius: 7px;
    color: teal;
    padding-left: 5px;
}

.groups {
    margin-top: 5px;
}

.all_stat p {
    margin-left: 5px;
}

.unique_radio {
    display: flex;
    gap: 5px;
    margin: 3px 0;
}

.unique_radio div {
    display: flex;
    gap: 3px;
}

.stat_graph {
    max-width: 700px;
}

@media screen and (max-width: 500px) {
    .buttons {
        flex-direction: column;
    }

    .btn button {
        width: 100%;
    }

    .graph div img {
        width: 100%;
    }

    .stat_graph {
        width: 100%;
    }
}