.input {
    width: 100%;
    height: 50px;
    padding: 5px 10px;
    margin: 5px 0;
    border: 2px solid teal;
    border-radius: 10px;
    color: teal;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    background-color: whitesmoke;
    outline: none;
}

.input::placeholder {
    font-size: 16px;
    color: teal;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}