* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: transparent;
}

.wrapper {
  border: 2px solid teal;
  border-radius: 20px;
  margin: 150px auto;
  width: 400px;
  padding: 20px;
  background: transparent;
}

.content {
  width: 100%;
  color: teal;
  margin: auto;
}


.content h1 {
  display: flex;
  justify-content: center;
  margin: 15px auto 25px auto;
}

.content li {
  display: flex;
  align-items: center;
  width: 100%;
}

.phone {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.pass {
  display: flex;
  width: 100%;
}

.pass button {
  margin: 5px 0;
  padding-right: 5px;
  width: 50px;
  border: 2px solid teal;
  border-left: none;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
  outline: none;
}

.pass button img {
  width: 90%;
  height: 70%;
}

.input_image {
  width: 100%;
  height: 50px;
  padding: 5px 10px;
  margin: 5px 0;
  border: 2px solid teal;
  border-right: none;
  border-radius: 10px 0 0 10px;
  color: teal;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  background-color: whitesmoke;
  outline: none;
}

.input_image::placeholder {
  font-size: 16px;
  color: teal;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.btn {
  display: flex;
  justify-content: center;
  height: 45px;
}

.my_modal {
  margin-top: 5px;
  margin-bottom: 10px;
}

/*@media screen and (max-width: 820px) and (min-width: 600px) {*/
/*  .wrapper {*/
/*    width: 500px;*/
/*  }*/

/*  .btn {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    margin-top: 15px;*/
/*  }*/
/*}*/

@media screen and (max-width: 600px) and (min-width: 360px) {
  .wrapper {
    width: 355px;
    margin-top: 70px;
  }

  .content {
    width: 98%;
  }

  .content h1 {
    margin: 0 auto 15px auto;
  }

  .btn {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}

@media screen and (max-width: 360px) {

  .wrapper {
    width: 300px;
    margin-top: 60px;
  }
  .content {
    flex-wrap: wrap;
    width: 99%;
  }

  .content h1 {
    margin: 0 auto 8px auto;
    font-size: 24px;
  }

  .btn {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}