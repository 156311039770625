.content {
    max-width: 700px;
    margin-left: 10px;
}

.buttons {
    display: flex;
    min-height: 40px;
    margin: 0 10px;
    gap: 5px;
}

.buttons button {
    border: 2px solid teal;
    border-radius: 7px;
    height: 45px;
    width: 150px;
    padding: 2px 5px;
    color: teal;
    font-size: 16px;
    cursor: pointer;
}

.client {
    color: teal;
    font-size: 16px;
    border: 2px solid teal;
    border-radius: 10px;
    padding: 10px;
    margin-left: 10px;
}

.client ul {
    list-style-type: none;
    font-size: 18px;
}

.client ul li {
    display: flex;
    align-items: center;
    margin: 5px;
}

.client ul li p {
    width: 125px;
    align-items: flex-start;
    flex-wrap: wrap;
}

.client ul li h5 a {
    color: teal;
    text-decoration: none;
}

.client ul hr {
    background: linear-gradient(45deg, teal, #47a8a8, whitesmoke);
    margin: 2px 0;
    height: 2px;
    border: none;
    width: 90%;
}

.loader {
    display: flex;
    justify-content: center;
}

.cardholder {
    color: teal;
    margin: 10px;
}

.radio {
    display: flex;
    gap: 10px;
}

.radio div {
    display: flex;
    gap: 2px;
}

.radio div label {
    font-weight: 700;
    font-size: 16px;
}

.modal {
    margin: 10px 0 5px 10px;
}

.verify_modal {
    margin-top: 10px;
}

.invisible {
    display: none;
}

.plug {

}

.button_plug {

}

.group_list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 3px;
}

.meetings {
    /*margin-left: 10px;*/
    color: teal;
}

.meetings_stat {
    /*max-width: 700px;*/
    color: teal;
    margin-left: 5px;
}

.graph {
    max-width: 695px;
    margin: 5px 0;
}

.range {
    margin: 3px 0 5px 0;
    display: flex;
    gap: 5px;
    align-items: center;
}

.input_date {
    min-height: 35px;
    border: 2px solid teal;
    border-radius: 5px;
    color: teal;
}

.hr {
    background: linear-gradient(45deg, teal, #47a8a8, whitesmoke);
    margin: 2px 0;
    height: 2px;
    border: none;
    width: 90%;
}

.verify {
    color: teal;
    margin-left: 10px;
}

.verify_select {
    display: flex;
    gap: 10px;
    align-items: center;
}

.verify_select p {
    width: 110px;
}

.verify_buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
    margin-top: 5px;
}

.unique_radio {
    display: flex;
    gap: 5px;
    margin: 3px 0;
}

.unique_radio div {
    display: flex;
    gap: 3px;
}

.queries_list {
    margin-left: 10px;
    color: teal;
}

.queries_list h3 {
    margin-bottom: 5px;
}

.queries_list .hr {
    margin: 10px 0;
}

.queries_list .column {
    display: flex;
    gap: 10px;
}

.queries_list .column h5 {
    font-size: 16px;
}

.queries_list div h5 {
    font-size: 16px;
}

@media screen and (max-width: 700px) and (min-width: 500px) {
    .content {
        max-width: 100%;
        margin-left: 0;
    }
    .client {
        width: 97%;
        margin-left: 6px;
        align-content: center;
    }

    .graph {
        max-width: 100%;
    }

    .meetings_stat {
        width: 97%;
        margin-left: 6px;
    }

    .hr {
        display: none;
    }

    .verify_select {
        flex-wrap: wrap;
        gap: 0;
    }

    .verify_select p {
        width: auto;
    }

    .verify {
        margin: 0 10px 0 5px;
        max-width: 100%;
    }
}

@media screen and (max-width: 499px) {
    .content {
        margin-left: 0;
        max-width: 100%;
    }

    .client {
        margin-right: 10px;
    }

    .buttons {
        flex-direction: column;
    }

    .buttons button {
        width: 100%;
    }

    .button_plug {
        width: 100%;
    }

    .radio {
        flex-direction: column;
    }

    .verify_select {
        flex-direction: column;
        gap: 0;
        margin-right: 10px;
    }

    .verify_select p {
        width: 100%;
    }

    .verify_buttons {
        margin: 5px 10px 10px 0;
    }

    .graph {
        width: 100%;
    }
}