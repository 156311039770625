.clients {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 5px;
    color: teal;
}

.loader {
    display: flex;
    justify-content: center;
}

.invisible {
    display: none;
}

.btns {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.headers_btn {
    display: flex;
    gap: 10px;
    margin: 5px 0 10px
}

.modal {
    margin-bottom: 5px;
}

.client_row {
    color: teal;
    text-decoration: none;
    margin-left: 5px;
}

.search_line {
    max-width: 700px;
}