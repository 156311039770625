.group {
    margin: 0 10px;
    max-width: 700px;
}

.buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    min-height: 40px;
}

.modal {
    margin-top: 10px;
}

.invisible {
    display: none;
}

@media screen and (max-width: 500px) {
    .buttons {
        flex-direction: column;
    }
}