.content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: transparent;
    margin: auto;
}

.body {
    max-width: 450px;
    border: 2px solid teal;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    color: teal;
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}


@media screen and (max-width: 650px) and (min-width: 500px) {
    .content {
        width: 450px;
        height: 450px;
        justify-content: normal;
    }
}

@media screen and (max-width: 500px) and (min-width: 359px) {
    .content {
        margin: auto;
    }

    .body {
        padding: 30px;
    }
}

@media screen and (max-width: 359px) {
    .content {
        margin: auto;
    }

    .body {
        padding: 20px 5px;
    }
}