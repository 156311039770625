.content {
    margin: 0 15px 20px 15px;
    /*width: 50%;*/
    max-width: 700px;
}

.group_card {
    border: 2px solid teal;
    border-radius: 10px;
    padding: 10px;
    color: teal;
    max-width: 700px;
}

.no_client div {
    display: flex;
    margin-bottom: 5px;
    width: 100%;
}

.no_client div p {
    width: 25%;
}

.no_client div h4 {
    width: 75%;
    word-wrap: anywhere;
}

.btns {
    display: flex;
    margin-bottom: 10px;
    gap: 5px;
    flex-wrap: wrap;
}

.btns button {
    border: 2px solid teal;
    border-radius: 7px;
    height: 45px;
    width: 150px;
    padding: 2px 5px;
    color: teal;
    font-size: 16px;
    cursor: pointer;
}

.btns2 {
    display: flex;
    margin-bottom: 5px;
    margin-top: 5px;
    gap: 5px;
    flex-wrap: wrap;
}

.search_clients {
    margin-top: 20px;
    color: teal;
}

.search_clients h3 {
    margin-bottom: 5px;
}

.invisible {
    display: none;
}

.headers_btn {
    display: flex;
    gap: 10px;
    margin: 5px 0 10px
}

.modal {
    margin: 10px 0;
}

.clients {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.loader {
    display: flex;
    justify-content: center;
}

.border_none {
    border: none;
}

.checkbox_client_list {
    display: flex;
    gap: 5px;
    font-size: 18px;
}

.clients_for_entry {
    color: teal;
}

.repeat {
    color: teal;
    margin: 5px 0;
}

.repeat p {
    margin-bottom: 5px;
}

.repeat input {
    width: 200px;
    height: 40px;
    color: teal;
    border: 2px solid teal;
    border-radius: 8px;
    padding: 5px 10px;
}

.repeat div {
    margin-top: 10px;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.repeat div button {
    width: 100px;
    height: 40px;
    border: 2px solid teal;
    border-radius: 8px;
    color: teal;
    font-size: 16px;
    cursor: pointer;
}

.hidden {
    visibility: hidden;
    width: 0;
    height: 0;
}

.plug {

}

@media screen and (max-width: 760px) and (min-width: 500px) {
    .content {
        width: 92%;
    }
    .group_card {
        width: 100%;
    }

    .no_client div {
        flex-direction: column;
    }

    .no_client div h4 {
        width: 100%;
    }

    .no_client p {
        width: 100%;
    }

    .modal {
        width: 100%;
    }
}

@media screen and (max-width: 499px){
    .btns {
        flex-direction: column;
    }

    .btns button {
        width: 100%;
    }

    .no_client div {
        flex-direction: column;
    }

    .no_client div p {
        width: 100%;
    }

    .no_client div h4 {
        width: 100%;
    }

    .headers_btn {
        gap: 5px;
        flex-direction: column;
    }

    .search_clients {
        margin-top: 5px;
    }
}